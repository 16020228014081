<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Création d'une offre spéciale
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-tabs
              grow
            >
              <v-tab>
                <v-icon left>
                  {{ icons.mdiPencil }}
                </v-icon>
                CMS
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiCameraOutline }}
                </v-icon>
                Images
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <h4>Description de l'offre spéciale</h4>
                        <br>
                        <v-text-field
                          v-model="name[currentLocale]"
                          label="Nom"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Nom"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="slug[currentLocale]"
                          label="Slug"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Slug"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="title[currentLocale]"
                          label="Titre"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="subTitle[currentLocale]"
                          label="Sous-titre"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Sous-titre"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="promotion[currentLocale]"
                          label="Texte promotionnel"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Texte promotionnel"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="entityData.position"
                          label="Position"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Position"
                          hide-details
                        ></v-text-field>

                        <v-switch
                          v-model="entityData.enabled"
                          :label="'Etat : ' + (entityData.enabled === true ? 'Activé' : 'Désactivé')"
                        ></v-switch>

                        <v-switch
                          v-model="entityData.inMenu"
                          :label="'Dans le menu : ' + (entityData.inMenu === true ? 'Oui' : 'Non')"
                        ></v-switch>

                        <v-switch
                          v-model="entityData.stamped"
                          :label="'Estampiller : ' + (entityData.stamped === true ? 'Oui' : 'Non')"
                        ></v-switch>

                        <h4>SEO</h4>
                        <br>

                        <v-text-field
                          v-model="seoTitle[currentLocale]"
                          label="Titre SEO"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre SEO"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="seoDescription[currentLocale]"
                          label="Description SEO"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Description SEO"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="seoKeywords[currentLocale]"
                          label="Mot clés"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Mot clés"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <div class="content-ckeditor-region">
                          <h4>Description</h4>
                          <br>
                          <ckeditor
                            v-model="description[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>

                        <br>

                        <div class="content-ckeditor-region">
                          <h4>Description bas de page</h4>
                          <br>
                          <ckeditor
                            v-model="bottomDescription[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>

                        <br>

                        <h4>Sélection de catégorie d'offre spéciale</h4>
                        <br>
                        <v-select
                          v-model="categorySelection"
                          :items="categories"
                          multiple
                          outlined
                          chips
                          hint="Choisir les campings à associées"
                          persistent-hint
                        ></v-select>

                        <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                        <h4>Date de début</h4>
                        <br>
                        <VDatePicker
                          v-model="entityData.startDate"
                          label="Date de début"
                        />

                        <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                        <h4>Date de fin</h4>
                        <br>
                        <VDatePicker
                          v-model="entityData.endDate"
                          label="Date de fin"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo principale"
                      image-name="mainImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <br>

                    <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo mobile"
                      image-name="mobileImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo page d'accueil"
                      image-name="homeImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'specialOfferList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraOutline, mdiMinusBox, mdiPencil, mdiCompassRose, mdiThumbUp, mdiSkiWater } from '@mdi/js'
import config from '../../../config'
import LocalSwitcher from '@/components/Common/LocalSwitcher.vue'
import Snackbar from '@/components/Common/Snackbar.vue'
import Loader from '@/components/Common/Loader.vue'
import ImageHandler from '@/components/Common/ImageHandler.vue'
import router from '@/router'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FullEditor from 'ckeditor5-custom-build'

export default {
  components: {
    ImageHandler,
    Snackbar,
    LocalSwitcher,
    Loader,
    ckeditor: CKEditor.component
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      editor: FullEditor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        simpleUpload: {
          uploadUrl: config.apiUrl + '/api/medias',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }
      },
      categories: [],
      categorySelection: [],
      name: [],
      slug: [],
      title: [],
      subTitle: [],
      description: [],
      bottomDescription: [],
      seoTitle: [],
      seoDescription: [],
      seoKeywords: [],
      promotion: [],
      entityData: {
        enabled: true,
        inMenu: true,
        stamped: true,
        startDate: null,
        endDate: null,
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {},
        mainImage: {
          id: null,
          title: null
        },
        mobileImage: {
          id: null,
          title: null
        },
        homeImage: {
          id: null,
          title: null
        }
      }
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.postSpecialOffer()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getCategories () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/special-offer/categories', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const categories = response.data

            for (let i = 0; i < categories.length; i++) {
              const currentCategory = categories[i]

              this.categories.push({
                text: currentCategory.translations.fr.name,
                value: currentCategory.id
              })
            }
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    postSpecialOffer () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      this.handleTranslations([
        'name',
        'slug',
        'title',
        'subTitle',
        'description',
        'bottomDescription',
        'seoTitle',
        'seoDescription',
        'seoKeywords',
        'promotion'
      ])

      const selectedCategory = []

      for (let i = 0; i < this.categorySelection.length; i++) {
        const currentCategory = this.categorySelection[i]

        selectedCategory.push({
          id: currentCategory
        })
      }

      this.entityData.specialOfferCategories = selectedCategory

      Vue.prototype.$http
        .post(config.apiUrl + '/api/special-offer/super-categories', this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            this.specialOffer = response.data

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Offre spéciale créée avec succès.'

            setTimeout(() => {
              router.push({ name: 'specialOfferDetails', params: { specialOfferId: this.specialOffer.id } })
            }, 1500)
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateLoading (loading) {
      this.loading = loading
    },
    updateSnackbar (snackbar) {
      this.snackbar = snackbar
    },
    updateSuccessMessage (successMessage) {
      this.successMessage = successMessage
    },
    updateValid (valid) {
      this.valid = valid
    }
  }
}
</script>

